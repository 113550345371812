





















































































































































































































import { Vue, Component } from "vue-property-decorator";
import Carousel from "@/components/Carousel.vue";
import BlogPost from "@/components/blog/BlogPost.vue";
import { CarouselItem } from "@/types/components/carousel";
// import { BlogPostItem } from "@/types/components/blog";

@Component({
    components: {
        Carousel,
        BlogPost,
    },
})
export default class Home extends Vue {
    public carouselItems: CarouselItem[] = [
        {
            imageSrc: require("@/assets/img/EntenrennenSlider2025.png"),
            heading: "Entenrennen",
            subheading:
                "Das vierte Entenrennen des Lions Club Sorpesee findet am 1. Mai, in Amecke statt",
            button: {
                content: "Mehr erfahren!",
                clicked: () => this.$router.push({ name: "projects" }),
            },
        },
        {
            imageSrc: require("@/assets/img/persons-hands-up.jpg"),
            heading: "Lions Sorpesee - We Serve!",
            subheading: null,
            button: null,
        },
        {
            imageSrc: require("@/assets/img/chartfeier2.jpg"),
            heading: "Chartfeier des Lions Club Sorpesee",
            subheading: "",
            button: {
                content: "Mehr erfahren!",
                clicked: () => this.$router.push({ name: "activities" }),
            },
        },
    ];

    /*
        private blogPosts: BlogPostItem[] = [
            {
                title: 'Blog Post 1',
                text: 'Zum Start des neuen #lionsclubsorpesee möchten wir unsere Gründungspräsidentin Maren Krombach vorstellen  Wir haben Maren gefragt: Warum jetzt? Warum hier?  Ihre Antwort: "Meine Familie und ich unterstützen sowohl mit unserem Unternehmen, als auch privat Familien und Kinder, die nicht vom Glück gesegnet sind. In den letzten Monaten haben wir aber leider feststellen müssen, dass das nicht ausreicht.  Die Pandemie hat ihre Spuren hinterlassen und dies besonders bei Kindern und Jugendlichen.  Aus diesem Grund habe ich die Entscheidung getroffen, eben dieses „mehr“ zu tun bzw. in Angriff zu nehmen und Menschen zusammen zu führen, die das genauso sehen, die etwas bewegen wollen, die helfen wollen!  Der Raum Sorpesee war bisher ein von Lions Clubs nicht besiedeltes Gebiet, genauso wenig gibt es in diesem Raum und angrenzend ein Angebot eines gemischten Clubs. Dies war für mich die Gelegenheit den Lions Club Sorpesee mit seinen 20 Gründungsmitgliedern ins Leben zu rufen.  Wir haben uns auf die Fahne geschrieben, gerade den Kindern und Jugendlichen, die während der Pandemie benachteiligt wurden Unterstützung zukommen zu lassen und Sie nach dem Motto in diesem Lionsjahr „…gemeinsam zurück ins Leben!“ zu führen." Die 20 Gründungsmitglieder freuen sich sehr auf das erste Jahr als Lions Club Sorpesee!',
                imageSrc: 'https://scontent-dus1-1.xx.fbcdn.net/v/t1.6435-9/212115229_107584708253968_7878156290247338316_n.jpg?_nc_cat=111&ccb=1-3&_nc_sid=973b4a&_nc_ohc=78kGjVL03vgAX9dLGvJ&_nc_ht=scontent-dus1-1.xx&oh=69f8b2cae8debc6b2ccb2c75fa7a93f9&oe=60FDBAF6'
            }
        ];
        */
}
